/* Sidebar styling */
.ant-layout-sider {
  background-color: #001529; /* Sidebar background color */
  position: fixed; /* Fix the sidebar to the left side */
  left: 0;
  top: 0;
  bottom: 0;
  width: 250px;
  height: 98vh;
  overflow-y: hidden;  
  z-index: 1000; /* Ensure the sidebar stays above other elements */
}

#root {
  height: 100%;
}

/* Content area */
.ant-layout-content {
  padding: 0px;
  overflow: auto;
}

/* Sidebar menu item styles */
.ant-menu-dark .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.2) !important; /* Highlight color for selected item */
  border-bottom: 1px solid #1890ff !important; /* Highlight color for selected item */
}

.ant-menu-item:hover {
  background-color: #40a9ff !important; /* Color on hover */
  color: #ffffff !important; /* Text color on hover */
}

.ant-menu-item {
  color: #ffffff !important; /* Default text color */
}

.ant-menu-dark .ant-menu-item-selected a {
  color: #ffffff !important; /* Text color of selected item */
}

.ant-menu-dark .ant-menu-item a {
  color: #ffffff !important; /* Default text color for links */
}

.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #ffffff !important; /* Icon color for selected item */
}

.ant-menu-dark .ant-menu-item .anticon {
  color: #ffffff !important; /* Default icon color */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .ant-layout-sider {
      display: none; /* Hide sidebar on small screens */
  }

  .ant-layout-content {
      margin: 0 !important; /* Remove margin on small screens */
  }

  .ant-layout-sider-trigger {
      display: block; /* Show trigger for opening sidebar */
      background-color: #001529 !important; /* Trigger background color */
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1000; /* Ensure trigger stays above other elements */
  }
}

/* Sidebar trigger (for responsive) */
body.ant-layout-has-sider .ant-layout-sider-trigger {
  background-color: #001529 !important; /* Sider trigger background color */
  bottom: 0;

  z-index: 1000; /* Ensure trigger stays above other elements */
}

/* Button styles */
.primary-button {
  background-color: #f0f2f5;
  min-width: 150px;
}

.custom-button {
  background-color: #ffffff;
  min-width: 150px;
  margin-right: 5px;
}

/* App general styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
      animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #001529;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

/* Card styles */
.ant-card {
  border-radius: 15px; /* Rounded corners for cards */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin: 0px;
}

.ant-card-head {
  background-color: #001529; /* Header background color */
  color: white; /* Header text color */
  border-radius: 8px 8px 0 0; /* Rounded corners for the top of the card */
}

.ant-card-body {
  padding: 0px; /* Increase padding for card content */
}

.ant-card-bordered {
  border: 1px solid #e8e8e8; /* Card border color */
}

/* Card hover effect */
.ant-card:hover {
  transform: translateY(-5px); /* Lift card slightly on hover */
  transition: transform 0.3s ease; /* Smooth transition */
}

/* Card actions styles */
.ant-card-actions > li {
  margin: 0;
  padding: 0;
  overflow-x: hidden;

}

.app-layout {
  min-height: 100vh;
}

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: var(--header-height);
  padding: 0;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 16px;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-logo {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}

.app-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  flex-grow: 1;
  text-align: left;
}

.main-layout {
  padding-top: calc(var(--header-height) - 16px); /* Reduced from var(--header-height) */
  min-height: calc(100vh - var(--header-height));
}

.app-sidebar {
  position: fixed;
  left: 0;
  top: var(--header-height);
  bottom: 0;
  width: var(--sidebar-width);
  background-color: #001529;
  overflow-y: auto;
  z-index: 999;
}

.content-area {
  margin-left: var(--sidebar-width);
  padding: 16px; /* Reduced from 24px */
  min-height: calc(100vh - var(--header-height));
  box-sizing: border-box;
  overflow-y: auto;
}

.mobile-menu-button {
  display: none;
}

.user-profile {
  display: flex;
  align-items: center;
}

.user-name {
  margin-right: 8px;
}

@media (min-width: 769px) {
 

  .app-sidebar {
    transform: translateX(0);
  }

  .content-area {
    padding-left: 50px;
  }
}

@media (max-width: 768px) {

  .main-layout {
    margin-left: 0;
  }

  .app-sidebar {
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .app-sidebar.visible {
    transform: translateX(0);
  }

  .content-area {
    margin-left: 0;
    width: 100%;
    padding: 16px;
  }

  .mobile-menu-button {
    display: none;
  }

  .user-name {
    display: none;
  }

  .app-title {
    font-size: 16px;
  }

  .header-logo {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}

/* Mobile menu styles */
.mobile-menu {
  position: fixed;
  top: var(--header-height);
  right: 0;
  width: 250px;
  height: calc(100vh - var(--header-height));
  background-color: #001529;
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
}

.mobile-menu.visible {
  transform: translateX(0);
}

.mobile-menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-menu-header h3 {
  color: #fff;
  margin: 0;
}

.mobile-menu .ant-menu {
  border-right: none;
  width: 100%;
}

.mobile-menu .ant-menu-item {
  margin: 0;
  padding: 0 16px;
  height: 40px;
  line-height: 40px;
}

.mobile-menu .ant-menu-item a {

  color: #fff;
  font-size: 14px;
}

.mobile-menu .ant-menu-item-icon {
  font-size: 16px;
  margin-right: 12px;
}

.mobile-menu .ant-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
  .header-logo {
    display: flex;
  }

  .app-title {
    font-size: 16px;
  }

  .mobile-menu-button {
    display: none;
  }

  .user-name {
    display: none;
  }
}

/* ... (keep other existing styles) ... */

/* Splash Screen styles */
.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #1E2A45, #2A3F5F);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.splash-content {
  text-align: center;
  color: #ffffff;
}

.splash-logo {
  width: 120px;
  height: auto;
  margin-bottom: 20px;
  animation: pulse 2s infinite;
}

.splash-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.loading-text {
  font-size: 16px;
  margin-top: 20px;
  opacity: 0.8;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* ... (keep other existing styles) ... */

/* Sidebar styles */
.sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebar-footer {
  margin-top: auto;
  padding: 10px;
  text-align: center;
}

.copyright-text {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.45);
  margin: 0;
}

/* Login styles */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 900px;
  /* min-height: 100vh; */
  /* width: 100vw; */
  /* background-color: #f0f2f5; */
  overflow: hidden;
  margin-left: calc(-1 * var(--sidebar-width));
  margin-top: var(--header-height);
}

.login-content {
  display: flex;
  width: 100%;
  max-width: 1000px;
  background-color: #fff;
  overflow: hidden;
}

.login-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  overflow: hidden;
}

.login-background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.login-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  overflow-y: hidden;
}

.login-form-inner {
  width: 100%;
  max-width: 400px;
}

.login-logo {
  width: 100px;
  height: auto;
  max-width: 30%;
  margin: 0 auto 32px;
  display: block;
}

.login-title {
  font-size: 28px;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 8px !important;
}

.login-subtitle {
  font-size: 16px;
  text-align: center;
  color: #666;
  margin-bottom: 32px;
}

.login-form-button,
.google-button {
  height: 40px;
  font-size: 16px;
  width: 100%;
}

.login-footer {
  margin-top: 24px;
  text-align: center;
}

.login-footer a {
  color: #1890ff;
}

@media (max-width: 768px) {
  .login-content {
    flex-direction: column;
  }

  .login-image {
    display: none;
  }

  .login-form {
    padding: 24px;
  }

  .login-logo {
    width: 80px;
    margin-bottom: 24px;
  }

  .login-title {
    font-size: 24px;
  }

  .login-subtitle {
    font-size: 14px;
    margin-bottom: 24px;
  }
}

/* ... (keep other existing styles) ... */

/* Appointment Modal styles */
.appointment-modal .ant-modal-content {
  max-height: 90vh;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .appointment-modal .ant-modal-body {
    padding: 12px;
  }
  
  .appointment-modal .ant-picker {
    width: 100%;
  }
}

/* ... (keep other existing styles) ... */

/* Appointment styles */
.appointment-date-picker {
  width: 100%;
}

.appointment-date-picker .ant-picker-input > input {
  font-size: 14px;
}

@media (max-width: 768px) {
  .appointment-date-picker .ant-picker-panels {
    flex-direction: column;
  }
}

/* ... (keep other existing styles) ... */

/* Custom Time Picker styles */
.appointment-time-picker {
  width: 100%;
}

.custom-time-picker-dropdown .ant-picker-panel-container {
  display: flex;
  flex-direction: column;
}

.custom-time-picker-dropdown .ant-picker-time-panel {
  width: 100%;
}

.custom-time-picker-dropdown .ant-picker-time-panel-column {
  flex: 1;
}

.custom-time-picker-dropdown .ant-picker-time-panel-cell {
  padding: 4px 0;
}

.custom-time-cell {
  padding: 8px;
  text-align: center;
}

.time-picker-footer {
  text-align: center;
  padding: 8px;
  font-size: 12px;
  color: #888;
}

@media (max-width: 768px) {
  .custom-time-picker-dropdown {
    width: 100% !important;
    max-width: 100%;
  }

  .custom-time-picker-dropdown .ant-picker-time-panel-column {
    width: 33.33% !important;
  }
}

/* ... (keep other existing styles) ... */

/* Dashboard styles */
.dashboard-page {
  padding: 12px;
}

.dashboard-content {
  margin-top: 12px;
}

.section-title {
  margin-top: 24px !important;
  margin-bottom: 12px !important;
  color: #1890ff;
}

.dashboard-card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transition: all 0.3s ease;
  height: 100%;
}

.dashboard-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.stat-card {
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stat-card .ant-statistic-title {
  color: #1890ff;
  font-weight: 600;
}

.stat-card .ant-statistic-content {
  color: #001529;
}

.action-card {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-card-content {
  text-align: center;
}

.action-card-icon {
  font-size: 28px;
  margin-bottom: 8px;
}

.action-card-text {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .dashboard-page {
    padding: 8px;
  }

  .dashboard-content {
    margin-top: 8px;
  }

  .section-title {
    margin-top: 16px !important;
    margin-bottom: 8px !important;
  }

  .stat-card,
  .action-card {
    height: 100px;
  }

  .action-card-icon {
    font-size: 24px;
  }

  .action-card-text {
    font-size: 12px;
  }

  .ant-col {
    padding: 4px !important;
  }
}

/* ... (keep other existing styles) ... */

/* General layout adjustments */
.ant-layout-content {
  padding: 12px;
}

.ant-card {
  margin-bottom: 12px;
}

.ant-form-item {
  margin-bottom: 12px;
}

/* Reduce side spaces for mobile */
@media (max-width: 768px) {
  .ant-layout-content {
    padding: 8px;
  }

  .ant-card-body {
    padding: 12px;
  }

  .ant-form-item {
    margin-bottom: 8px;
  }

  .ant-row {
    margin-right: -4px !important;
    margin-left: -4px !important;
  }

  [class*="ant-col-"] {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
}

/* ... (keep other existing styles) ... */

/* Reports page styles */
.ant-card {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  transition: all 0.3s;
}

.ant-card:hover {
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
}

.ant-card-head-title {
  font-size: 18px;
  color: #1890ff;
}

.ant-statistic-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}

.ant-statistic-content {
  font-size: 24px;
  font-weight: bold;
  color: #1890ff;
}

.ant-progress-text {
  font-weight: bold;
}

.ant-table-thead > tr > th {
  background-color: #f0f5ff;
  color: #1890ff;
}

.recharts-default-tooltip {
  background-color: rgba(255, 255, 255, 0.9) !important;
  border: 1px solid #e8e8e8 !important;
}

/* ... (keep other existing styles) ... */

/* DayAppointmentView styles */
.day-appointment-view {
  padding: 16px;
}

.day-appointment-view .header-row {
  margin-bottom: 16px;
}

.day-appointment-view .button-group {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
}

.day-appointment-view .button-group .ant-btn {
  margin-left: 8px;
}

@media (max-width: 576px) {
  .day-appointment-view {
    padding: 8px;
  }

  .day-appointment-view .header-row {
    margin-bottom: 8px;
  }

  .day-appointment-view .button-group {
    justify-content: space-between;
    margin-top: 8px;
  }

  .day-appointment-view .button-group .ant-btn {
    margin-left: 0;
    padding: 0 8px;
    font-size: 12px;
  }
}

/* ... (keep other existing styles) */

/* BookingCalendar styles */
.booking-calendar .ant-card-body {
  padding: 0;
}

.booking-calendar .ant-tabs-nav {
  margin-bottom: 16px;
}

.booking-calendar .events {
  list-style: none;
  margin: 0;
  padding: 0;
}

.booking-calendar .events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.booking-calendar .ant-badge-status-text {
  margin-left: 8px;
}

.booking-calendar .ant-list-item-action {
  margin-left: 48px;
}

.booking-calendar .ant-list-item-meta-description {
  margin-top: 8px;
}

.booking-calendar .ant-tag {
  margin-right: 8px;
}

/* Add these styles to your existing index.css file */

.ant-layout-header {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 64px;
}

.ant-layout-sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 64px;
  bottom: 0;
}

.ant-layout-content {
  /* margin-left: 200px; */
  margin-top: 64px;
  max-width: calc(100vw - var(--sidebar-width));
}

/* AppointmentsPage styles */
.appointments-page {
  padding: 0px;
}

.page-header-card {
  margin-bottom: 24px;
}

.appointments-table-card {
  overflow-x: auto;
}

.booking-calendar-modal {
  top: 20px;
}

@media (max-width: 768px) {
  .appointments-page {
    margin-left: 0; /* Remove margin on mobile devices */
    padding: 16px;
  }

  .page-header-card .ant-card-body {
    padding: 16px;
  }

  .page-header-card .ant-typography {
    margin-bottom: 16px;
  }

  .page-header-card .ant-space {
    margin-bottom: 16px;
  }
}

.user-guide-page {
  padding: 16px;
  margin-top: calc(-1 * var(--header-height));
  min-height: calc(100vh - var(--header-height));
}

@media (max-width: 768px) {
  .user-guide-page {
    margin-top: var(--header-height);
  }
}
/* Adjust main layout content */
.ant-layout-content {
  margin-left: var(--sidebar-width);
  margin-top: var(--header-height);
  padding: 24px;
  min-height: calc(100vh - var(--header-height));
}

@media (max-width: 768px) {
  .ant-layout-content {
    margin-left: 0;
    padding: 16px;
  }
}

/* ... (keep other existing styles) ... */

/* Add this to your existing styles */
.reports-page {
  padding: 24px;
  margin-top: calc(-1 * var(--header-height));
  box-sizing: border-box;
  width: 100%;
}

@media (min-width: 769px) {
  .reports-page {
    /* margin-left: var(--sidebar-width); */
    width: 100vw;
  }
}

@media (max-width: 768px) {
  .reports-page {
width: 100%;  /* width: 100%; */
  }
}

/* Specific page styles */
.reports-page,
.appointments-page {
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .reports-page,
  .appointments-page {
    padding: 16px;
  }
}

/* Ensure all charts and tables are responsive */
.recharts-responsive-container {
  width: 100% !important;
  height: 300px !important;
}

.ant-table-wrapper {
  width: 100%;
  overflow-x: auto;
}

/* Adjust card styles for better mobile view */
.ant-card {
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .ant-card-head-title {
    font-size: 16px;
  }
  
  .ant-statistic-title {
    font-size: 12px;
  }
  
  .ant-statistic-content {
    font-size: 20px;
  }
}

:root {
  --header-height: 64px;
  --sidebar-width: 250px;
}

.app-layout {
  min-height: 100vh;
}

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: var(--header-height);
  padding: 0;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.main-layout {
  padding-top: calc(var(--header-height) - 16px); /* Reduced from var(--header-height) */
  min-height: calc(100vh - var(--header-height));
}

.app-sidebar {
  position: fixed;
  left: 0;
  top: var(--header-height);
  bottom: 0;
  width: var(--sidebar-width);
  background-color: #001529;
  overflow-y: auto;
  z-index: 999;
}

.content-area {
  margin-left: var(--sidebar-width);
  padding: 16px; /* Reduced from 24px */
  min-height: calc(100vh - var(--header-height));
  box-sizing: border-box;
  overflow-y: auto;
}

@media (max-width: 768px) {
  :root {
    --header-height: 56px; /* Slightly reduce header height for mobile */
    --sidebar-width: 0px; /* Set sidebar width to 0 for mobile */
  }

  .app-header {
    height: var(--header-height);
    padding: 0 10px;
  }

  .header-content {
    padding: 0;
  }

  .header-logo {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .app-title {
    font-size: 16px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-name {
    display: none;
  }

  .main-layout {
    padding-top: calc(var(--header-height) - 8px); /* Further reduced for mobile */
  }

  .app-sidebar {
    transform: translateX(-100%);
    width: 80%; /* Adjust sidebar width for mobile */
    max-width: 300px;
  }

  .app-sidebar.visible {
    transform: translateX(0);
  }

  .content-area {
    margin-left: 0;
    padding: 12px; /* Reduced from 16px for mobile */
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
  }

  .ant-layout-content {
    margin: 0 !important;
    padding: 16px;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
  }

  .dashboard-page,
  .appointments-page,
  .reports-page {
    padding: 16px;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
  }

  .ant-card {
    width: 100%;
  }

  .ant-table {
    width: 100%;
    overflow-x: auto;
  }

  .ant-statistic-title {
    font-size: 14px;
  }

  .ant-statistic-content {
    font-size: 20px;
  }

  .action-card {
    height: auto;
    padding: 16px;
  }

  .action-card-icon {
    font-size: 24px;
  }

  .action-card-text {
    font-size: 12px;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-picker {
    width: 100%;
  }

  .ant-modal {
    width: 90% !important;
    max-width: none;
  }

  .ant-modal-body {
    padding: 16px;
  }

  .appointment-modal .ant-modal-body {
    padding: 16px;
  }

  .appointment-date-picker,
  .appointment-time-picker {
    width: 100%;
  }

  .login-form-container {
    padding: 16px;
  }

  .login-form-content {
    width: 100%;
    padding: 16px;
  }

  .booking-calendar .ant-picker-panels {
    flex-direction: column;
  }

  .custom-time-picker-dropdown {
    width: 100% !important;
    max-width: 100%;
  }

  .custom-time-picker-dropdown .ant-picker-time-panel-column {
    width: 33.33% !important;
  }

  /* Ensure no horizontal scrolling */
  body, #root, .app-layout {
    max-width: 100vw;
    overflow-x: hidden;
  }

  /* Adjust grid layout for better mobile view */
  .ant-row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  [class*="ant-col-"] {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
}

/* ... (keep existing styles) ... */

/* ... (keep existing styles) */

.appointment-card .ant-card-body {
  padding: 10px;
}

.appointment-card .ant-row {
  width: 100%;
}

.appointment-card .ant-col {
  margin-top: 8px;
}

.appointment-card .ant-btn {
  width: 100%;
}

/* ... (keep other existing styles) */

/* Add these styles */
.appointment-card .ant-space-compact {
  width: 100%;
  display: flex;
}

.appointment-card .ant-space-compact .ant-btn {
  flex: 1;
  white-space: nowrap;
}

@media (max-width: 576px) {
  .appointment-card .ant-space-compact .ant-btn {
    padding: 4px;
    font-size: 12px;
  }
}